import React, { useState } from 'react';
import {
  CssBaseline,
  Box,
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Paper,
  Alert,
} from '@mui/material';
import InputMask from 'react-input-mask';
import { motion } from 'framer-motion';
import logo from './assets/teste-logo-removebg-preview.png';
import logoAzul from './assets/logoazul.png';


const PhoneField = ({ handleChange }: any) => {
  return (
    <InputMask
      mask="(99) 99999-9999"
      onChange={handleChange}
    >
      {() => (
        <TextField
          fullWidth
          label="Telefone"
          variant="outlined"
          margin="normal"
          required
          name="phone"
        />
      )}
    </InputMask>
  );
};

const fadeInUp = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
};

const staggerContainer = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.2 } },
};

function App() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/5551985057352', '_blank'); // Substitua pelo número correto do WhatsApp
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xldezeez', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setStatus('success');
        setFormData({ name: '', email: '', phone: '', message: '' }); // Limpa o formulário
      } else {
        setStatus('error');
      }
    } catch {
      setStatus('error');
    }
  };

  return (
    <>
      <CssBaseline />
      {/* Navbar */}
      <AppBar position="static">
        <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img src={logo} alt="Logo QuiroWeb" style={{ height: 80, marginRight: 10 }} />
          <Typography variant="h6" component="div">
            QuiroWeb
          </Typography>
        </Box>
          <Button color="inherit" onClick={handleWhatsAppClick}>
            Fale Conosco
          </Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          py: 8,
          textAlign: 'center',
        }}
        component={motion.div}
        variants={fadeInUp}
        initial="initial"
        animate="animate"
      >
        <Container maxWidth="md">
        <motion.div variants={fadeInUp}>
          <img src={logoAzul} alt="Logo QuiroWeb" style={{ height: 100, marginBottom: 10 }} />
          <Typography variant="h2" component="h1" gutterBottom>
            Eleve sua clínica de Quiropraxia ao próximo nível
          </Typography>
        </motion.div>
          <motion.div variants={fadeInUp}>
            <Typography variant="body1" sx={{ mb: 4 }}>
              A QuiroWeb cria sites modernos e personalizados para quiropraxistas. Aumente sua visibilidade, atraia mais pacientes e construa sua marca online.
            </Typography>
          </motion.div>
          <motion.div variants={fadeInUp}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleWhatsAppClick}
            >
              Fale com um especialista
            </Button>
          </motion.div>
        </Container>
      </Box>

      {/* About Section */}
      <Container
        maxWidth="md"
        sx={{ py: 6 }}
        component={motion.div}
        variants={staggerContainer}
        initial="initial"
        animate="animate"
      >
        <Typography variant="h4" gutterBottom>
          Por que um site profissional faz a diferença?
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <motion.div variants={fadeInUp}>
              <Typography variant="h6">📈 Aumente sua visibilidade</Typography>
              <Typography variant="body1">
                Um site bem desenvolvido coloca sua clínica no radar de novos pacientes e facilita a comunicação com os atuais.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div variants={fadeInUp}>
              <Typography variant="h6">💡 Destaque sua especialidade</Typography>
              <Typography variant="body1">
                Mostre seus serviços, depoimentos de clientes e conteúdo informativo que agrega valor à sua prática.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div variants={fadeInUp}>
              <Typography variant="h6">⏱️ Economize tempo</Typography>
              <Typography variant="body1">
                Automatize agendamentos e reduza o tempo gasto com processos administrativos.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div variants={fadeInUp}>
              <Typography variant="h6">💼 Profissionalismo</Typography>
              <Typography variant="body1">
                Transmita confiança e credibilidade com um site que reflete a qualidade da sua clínica.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </Container>

      {/* Mission Section */}
      <Box
        sx={{
          backgroundColor: '#e3f2fd',
          py: 6,
          textAlign: 'center',
        }}
        component={motion.div}
        variants={fadeInUp}
        initial="initial"
        animate="animate"
      >
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            Nossa Missão
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Proporcionar aos quiropraxistas uma presença digital poderosa, promovendo suas clínicas e tornando-as acessíveis para todos que buscam bem-estar e saúde.
          </Typography>
        </Container>
      </Box>

      {/* Contact Form */}
      <Container
        maxWidth="sm"
        sx={{ py: 6 }}
        component={motion.div}
        variants={fadeInUp}
        initial="initial"
        animate="animate"
      >
        <Typography variant="h4" gutterBottom>
          Entre em contato
        </Typography>
        <Paper sx={{ p: 4 }}>
          <form
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              label="Nome"
              variant="outlined"
              margin="normal"
              type="text"
              name="name"
              value={formData.name}
              required
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="E-mail"
              variant="outlined"
              margin="normal"
              required
              name="email"
              value={formData.email}
              type="email"
              onChange={handleChange}
            />
            <PhoneField
              handleChange={handleChange}
              value={formData.phone}
            />
            <TextField
              fullWidth
              label="Mensagem"
              variant="outlined"
              margin="normal"
              required
              multiline
              rows={4}
              name="message"
              value={formData.message}
              type="text"
              onChange={handleChange}
            />
            <Button 
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Enviar
            </Button>
          </form>
           
          {/* Exibe mensagens de sucesso ou erro */}
           {status === 'success' && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Mensagem enviada com sucesso!
            </Alert>
          )}
          {status === 'error' && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Ocorreu um erro ao enviar a mensagem. Tente novamente mais tarde.
            </Alert>
          )}
        </Paper>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: '#333',
          color: '#fff',
          py: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} QuiroWeb. Todos os direitos reservados.
        </Typography>
      </Box>
    </>
  );
}

export default App;
